// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export function pageview(url) {
  if (typeof window !== "undefined" && window.gtag) {
    window.gtag("config", process.env.NEXT_PUBLIC_GA_TRACKING_ID, {
      page_path: url,
    });
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export function event({ action, category, label, value }) {
  if (typeof window !== "undefined" && window.gtag) {
    window.gtag("event", action, {
      event_category: category,
      event_label: label,
      value: value,
    });
  }
}

export function config(config) {
  if (typeof window !== "undefined" && window.gtag) {
    window.gtag("set", config);
  }
}
