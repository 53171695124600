import { getSupabaseResource, supabase } from "utils/supabase";
import ConstResource from "resources/ConstResource";

import { RestaurantContext } from "contexts";
import { useRouter } from "next/router";

const nullResource = new ConstResource(null);

export default function RestaurantProvider({ children }) {
  const router = useRouter();
  const restaurantSlug = router.query.restaurantSlug;

  const resource = restaurantSlug
    ? getSupabaseResource(
        supabase
          .from("restaurants")
          .select(
            `
              *,
              order_types: order_types!restaurant_id (*)
            `
          )
          .eq("slug", restaurantSlug),
        { single: true }
      )
    : nullResource;

  return (
    <RestaurantContext.Provider value={resource}>
      {children}
    </RestaurantContext.Provider>
  );
}
