import * as Sentry from "@sentry/node";
import SentryRRWeb from "@sentry/rrweb";
import { Integrations } from "@sentry/tracing";
import { RewriteFrames } from "@sentry/integrations";

export const sentry = {
  initialize() {
    let integrations = [];

    if (
      process.env.NEXT_IS_SERVER === "true" &&
      process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR
    ) {
      // For Node.js, rewrite Error.stack to use relative paths, so that source
      // maps starting with ~/_next map to files in Error.stack with path
      // app:///_next
      integrations.push(
        new RewriteFrames({
          iteratee: (frame) => {
            frame.filename = frame.filename.replace(
              process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR,
              "app:///"
            );
            frame.filename = frame.filename.replace(".next", "_next");
            return frame;
          },
        })
      );
    }

    if (typeof window !== "undefined") {
      integrations.push(new Integrations.BrowserTracing());
      integrations.push(
        new SentryRRWeb({
          checkoutEveryNms: 1000 * 30,
          maskAllInputs: false,
        })
      );
    }

    Sentry.init({
      enabled: process.env.NODE_ENV === "production",
      environment: process.env.NODE_ENV,
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
      release: process.env.NEXT_PUBLIC_COMMIT_SHA,
      integrations,
      tracesSampleRate: 0.2,
    });

    if (typeof window !== "undefined") {
      Sentry.setTag("rrweb.active", "yes");
    }
  },
};
