import NoSleep from "utils/nosleep";
import { useMemo } from "react";
import { NoSleepContext } from "contexts";

export default function NoSleepProvider({ children }) {
  const noSleep = useMemo(() => new NoSleep(), []);

  return (
    <NoSleepContext.Provider value={noSleep}>
      {children}
    </NoSleepContext.Provider>
  );
}
