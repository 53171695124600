import * as Sentry from "@sentry/node";

import { supabase } from "utils/supabase";
import * as gtag from "utils/gtag";
import ObservableResource from "./ObservableResource";

import Router from "next/router";

async function persistSession(session) {
  if (session?.user)
    await fetch("/api/auth/set-cookie", {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      credentials: "same-origin",
      body: JSON.stringify({
        event: "SIGNED_IN",
        session: supabase.auth.session(),
      }),
    });
  else
    await fetch("/api/auth/remove-cookie", {
      method: "GET",
      credentials: "same-origin",
    });

  Sentry.setUser({
    id: session?.user?.id,
    email: session?.user?.email,
  });
  gtag.config({ user_id: session?.user?.id });
}

export default class AuthResource extends ObservableResource {
  constructor() {
    super(
      new Promise((resolve) => {
        if (typeof window == "undefined")
          resolve({ session: null, user: null });
        else
          supabase.auth.refreshSession().then(async () => {
            const session = supabase.auth.session();
            await persistSession(session);
            resolve({ session, user: session?.user ?? null });
          });
      })
    );
    supabase.auth.onAuthStateChange(async (event, session) => {
      await persistSession(session);
      this.onNext({ session, user: session?.user ?? null });
      if (event === "PASSWORD_RECOVERY") Router.push("/auth/reset-password");
    });
  }
}

export const authResource = new AuthResource();
