import * as gtag from "utils/gtag";

import { useEffect } from "react";
import { useRouter } from "next/router";

export default function Analytics() {
  const router = useRouter();

  useEffect(() => {
    const onRouteChangeComplete = (url) => {
      gtag.pageview(url);
    };
    router.events.on("routeChangeComplete", onRouteChangeComplete);
    return () => {
      router.events.off("routeChangeComplete", onRouteChangeComplete);
    };
  }, [router.events]);

  return null;
}
