import * as Sentry from "@sentry/node";

import React, { useEffect, useState } from "react";

import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import Button from "components/ui/Button";

const ErrorFallback = ({ error, componentStack, resetErrorBoundary }) => {
  const [eventId, setEventId] = useState(null);

  useEffect(() => {
    setEventId(
      Sentry.captureException(error, {
        contexts: { react: { componentStack } },
      })
    );
  }, [error, componentStack]);

  return (
    <div className="flex flex-col items-center justify-center h-full p-4 space-y-4">
      <h2 className="text-xl font-bold">Something went wrong</h2>
      <p className="max-w-xs">We&apos;ve been notified of this error.</p>
      <div className="flex items-center space-x-4">
        <Button
          className="px-4 py-2 border rounded-md hover:bg-gray-100 font-bold"
          onClick={() => Sentry.showReportDialog({ eventId })}
        >
          Give feedback
        </Button>
        <Button
          className="px-4 py-2 rounded-md text-white font-bold"
          bgColor="primary"
          onClick={() => resetErrorBoundary()}
        >
          Try again
        </Button>
      </div>
    </div>
  );
};

export default function ErrorBoundary(props) {
  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorFallback}
      resetKeys={[props.children]}
      onReset={() => window.location.reload()}
      {...props}
    />
  );
}
