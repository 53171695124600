import "react-day-picker/lib/style.css";
import "styles/index.css";

import { hotjar } from "react-hotjar";
import { sentry } from "utils/sentry";

import { useEffect } from "react";
import { useRouter } from "next/router";
import useAuth from "hooks/useAuth";

import Analytics from "containers/Analytics";
import ErrorBoundary from "containers/ErrorBoundary";
import SSRSuspense from "containers/SSRSuspense";
import RestaurantProvider from "containers/RestaurantProvider";
import NoSleepProvider from "containers/NoSleepProvider";

import Notifications from "components/notifications";
import Loader from "components/ui/Loader";

sentry.initialize();

function AuthBoundary({ Component, pageProps, err }) {
  const auth = useAuth();

  const { isAuthorized, redirect } = Component.authPolicy;
  const router = useRouter();
  const authorized = isAuthorized(auth);

  useEffect(() => {
    if (!authorized && router.isReady) router.replace(redirect);
  }, [authorized, redirect, router]);

  return authorized ? <Component auth={auth} {...pageProps} err={err} /> : null;
}

function QueryBoundary({ Component, pageProps, err }) {
  const router = useRouter();
  const hydrated = !(
    router.pathname.includes("[") && Object.keys(router.query).length === 0
  );

  return hydrated || Component.renderUnhydrated ? (
    <RestaurantProvider>
      {Component.authPolicy ? (
        typeof window !== "undefined" && (
          <AuthBoundary Component={Component} pageProps={pageProps} err={err} />
        )
      ) : (
        <Component {...pageProps} err={err} />
      )}
    </RestaurantProvider>
  ) : null;
}

export default function MyApp({ Component, pageProps, err }) {
  useEffect(() => {
    hotjar.initialize(2192474, 6);
  }, []);

  return (
    <ErrorBoundary>
      <SSRSuspense
        fallback={
          <div className="flex items-center justify-center h-screen">
            <Loader className="text-primary-600" />
          </div>
        }
      >
        <NoSleepProvider>
          <QueryBoundary
            Component={Component}
            pageProps={pageProps}
            err={err}
          />
          <Notifications />
          <Analytics />
        </NoSleepProvider>
      </SSRSuspense>
    </ErrorBoundary>
  );
}
