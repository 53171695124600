import cx from "classnames";

import Cross from "components/icons/Cross";
import Button from "components/ui/Button";

export default function Notification({ notification, onClose }) {
  return (
    <div
      className={`rounded-md text-white p-4 space-y-2 bg-${notification.color}-500`}
    >
      <div className="flex items-start justify-between space-x-4">
        <p>{notification.content}</p>
        {notification.dismissable && (
          <Button
            className={cx(
              "p-1 rounded-md",
              `hover:bg-${notification.color}-600`
            )}
            onClick={onClose}
          >
            <Cross className="h-4" />
          </Button>
        )}
      </div>
      {notification.action && (
        <div className="flex items-center justify-end space-x-4">
          <Button
            className="px-3 py-1 rounded-md border font-bold"
            bgColor={notification.color}
            onClick={async (event) => {
              await notification.action.onAct(event);
              onClose();
            }}
          >
            {notification.action.label}
          </Button>
        </div>
      )}
    </div>
  );
}
